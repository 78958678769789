<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-show="!isLoading">
      <button
        type="button"
        class="bg-green-1000 hover:bg-green-1001 text-white px-4 py-2 rounded-lg focus:outline-none"
        @click="goBack"
      >
        Voltar
      </button>
      <div class="py-4 flex">
        <h1 class="text-3xl">Sugestões {{ participant.name }}</h1>
      </div>

      <div class="p-4">
        <v-client-table
          v-once
          ref="table"
          :data="sugestions"
          :columns="columns"
          :options="options"
        >
          <div class="hidden justify-center items-center" slot="actions">
            <div class="flex justify-center">
              <button
                class="px-3 border-2 border-green-1003 bg-green-1003 hover:bg-green-1001 hover:border-green-1001 text-white w-max rounded-lg mr-3"
              >
                Enviar Sugestão
              </button>
            </div>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import axios from '@/utils/axios';
import DashboardLayout from '../../../layouts/DashboardLayout';
import { mapGetters } from 'vuex';
import LoaderAnimation from '../../../components/LoaderAnimation';

export default {
  name: 'Index',

  title() {
    return `${process.env.VUE_APP_NAME} | Plano de Ação`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: true,
      participant: {
        name: ''
      },
      sugestions: [],
      columns: [
        'id',
        'programming_type',
        'name',
        'occupational_area',
        'started_at',
        'ended_at',
        'actions'
      ],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          programming_type: 'Tipo de Programação',
          occupational_area: 'Área Ocupacional',
          started_at: 'Data Ini.',
          ended_at: 'Data Fim',
          actions: 'Ações'
        },
        hiddenColumns: ['id', 'occupational_area'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} sugestões|{count} sugestões|Uma sugestão',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhuma sugestão encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },
  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
      authSyndicate: 'syndicate'
    })
  },
  methods: {
    fetchSugestions() {
      const idSyndicate = this.authSyndicate.id ?? '';

      axios
        .get(
          `/api/planos-acao/sugestoes/${this.$route.params.id}/${this.$route.params.participant}/${idSyndicate}`
        )
        .then(({ data }) => {
          this.participant.name = data.data.participant;

          data.data.sugestions.forEach(sugestions => {
            this.sugestions.push(sugestions);
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    }
  },

  created() {
    this.fetchSugestions();
  }
};
</script>

<style scoped></style>
