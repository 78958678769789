<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-show="!isLoading">
      <button
        type="button"
        class="bg-green-1000 hover:bg-green-1001 text-white px-4 py-2 rounded-lg focus:outline-none"
        @click="goBack"
      >
        Voltar
      </button>
      <div class="py-4 flex flex-col">
        <h3>Categoria: {{ course.programming_type }}</h3>
        <h1 class="text-3xl text-green-1002">{{ course.name }}</h1>
      </div>

      <div class="p-4">
        <fieldset class="border p-5 border-green-1002 rounded-lg">
          <legend class="p-2 text-2xl">Talentos interessados</legend>
          <v-client-table
            ref="table"
            :data="participants"
            :columns="columns"
            :options="options"
          >
            <div
              class="flex justify-center items-center"
              slot="resume"
              slot-scope="{ row }"
            >
              <router-link
                class="flex p-1 border-2 w-max rounded-lg mr-4 border-yellow-500 hover:bg-yellow-500 text-yellow-500 focus:outline-none focus:shadow-outline has-tooltip"
                :to="{ name: 'curriculum', params: { id: row.participant } }"
                v-tooltip="{ content: 'Ver Currículo', placement: 'left' }"
                target="_blank"
              >
                <i
                  class="ri-article-line py-0 px-2 text-2xl hover:text-green-50"
                ></i>
              </router-link>
            </div>
            <div
              class="flex justify-center items-center"
              slot="action"
              slot-scope="{ row }"
            >
              <div class="flex justify-center">
                <router-link
                  class="flex px-3 border-2 border-green-1003 bg-green-1003 hover:bg-green-1001 hover:border-green-1001 text-white w-max rounded-lg mr-3"
                  :to="{
                    name: 'sugestions',
                    params: { id: $attrs.id, participant: row.participant }
                  }"
                >
                  Sugerir<i class="ml-1 ri-add-line"></i>
                </router-link>
              </div>
            </div>
          </v-client-table>
        </fieldset>
        <fieldset class="border p-5 border-green-1002 rounded-lg">
          <legend class="p-2 text-2xl">Associados interessados</legend>
          <v-client-table
            ref="table"
            :data="associates"
            :columns="columnsAssociate"
            :options="options"
          >
            <div
              class="flex justify-center items-center"
              slot="action"
              slot-scope="{ row }"
            >
              <button
                class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4 hover:bg-green-1000"
                @click="showModal(row)"
                v-tooltip="{ content: 'Ver mensagens', placement: 'left' }"
              >
                <i
                  class="ri-message-2-line py-0 px-2 text-2xl text-green-1002 hover:text-green-50"
                ></i>
              </button>
            </div>
          </v-client-table>
        </fieldset>
      </div>
    </div>

    <!-- Modal -->
    <modal-layout :width="'90%'">
      <template v-slot:body>
        <loader-animation v-if="isLoadingModal" :height="'h-80'" />
        <div v-else>
          <div class="flex justify-center items-center flex-col my-5">
            <h2 class="font-bold">
              {{ modalMessage.course.course_name }}
            </h2>
            <span
              >{{ modalMessage.course.started_at }} a
              {{ modalMessage.course.ended_at }}</span
            >
          </div>
          <fieldset class="border border-green-1002 rounded-lg px-3 m-4">
            <legend class="text-black text-xl">
              Dados da empresa
            </legend>
            <div class="flex justify-start items-center flex-wrap">
              <div class="flex items-center mx-1">
                <div class="m-4"><i class="ri-service-line"></i></div>
                <div>
                  <span class="text-sm font-bold"> Associado</span>
                  <p class="text-black">
                    {{ modalMessage.associate.associate_name }}
                  </p>
                </div>
              </div>

              <div class="flex items-center mx-1">
                <div class="m-4"><i class="ri-community-line"></i></div>
                <div>
                  <span class="text-sm font-bold">Tipo</span>
                  <p class="text-black">
                    {{
                      modalMessage.associate.associate_name == 1
                        ? 'Proprietário'
                        : modalMessage.associate.associate_name == 2
                        ? 'Parceiro'
                        : 'Empresa de Agronegócios'
                    }}
                  </p>
                </div>
              </div>

              <div class="flex items-center mx-1">
                <div class="m-4"><i class="ri-map-pin-line"></i></div>
                <div>
                  <span class="text-sm font-bold">Endereço</span>
                  <p class="text-black">
                    {{ modalMessage.associate.address }}
                  </p>
                </div>
              </div>

              <div class="flex items-center mx-1">
                <div class="m-4"><i class="ri-phone-line"></i></div>
                <div>
                  <span class="text-sm font-bold">Telefone Fixo</span>
                  <p class="text-black">
                    {{ modalMessage.associate.cellphone }}
                  </p>
                </div>
              </div>

              <div class="flex items-center mx-1">
                <div class="m-4"><i class="ri-smartphone-line"></i></div>
                <div>
                  <span class="text-sm font-bold">Telefone Celular</span>
                  <p class="text-black">
                    {{ modalMessage.associate.telephone }}
                  </p>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="border border-green-1002 rounded-lg px-3 m-4">
            <legend class="text-black text-xl">
              Solicitação
            </legend>
            <div class="flex justify-start items-center flex-wrap">
              <div class="flex items-center mx-1">
                <div class="m-4"><i class="ri-user-line"></i></div>
                <div>
                  <span class="text-sm font-bold">Usuário Solicitante</span>
                  <p class="text-black">
                    {{ modalMessage.associate.message[0].user_name }}
                  </p>
                </div>
              </div>

              <div class="flex items-center mx-1">
                <div class="m-4"><i class="ri-calendar-line"></i></div>
                <div>
                  <span class="text-sm font-bold">Data da solicitação</span>
                  <p class="text-black">
                    {{ modalMessage.associate.message[0].created_at }}
                  </p>
                </div>
              </div>

              <div class="flex items-center mx-1">
                <div class="m-4"><i class="ri-group-line"></i></div>
                <div>
                  <span class="text-sm font-bold"
                    >Quantidade de Colaboradores</span
                  >
                  <p class="text-black">
                    {{ modalMessage.associate.qtdCollaborators }} Colaboradores
                  </p>
                </div>
              </div>
            </div>
          </fieldset>
          <span class="text-sm font-bold ml-5">Mensagens</span>
          <hr class="col" />

          <div class="h-60 overflow-auto w-full text-black inline-block p-4">
            <div
              class="border border-green-600 p-1.5 mt-3 w-3/5 rounded-md"
              :class="
                value.syndicate_name ? 'float-right' : 'float-left'
              "
              v-for="(value, key) in modalMessage.associate.message"
              :key="key"
            >
              <span class="text-xs"
                >{{ value.user_name }} –
                {{ value.created_at }}</span
              >
              <div>
                <p>
                  {{ value.message }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex justify-between items-baseline m-5">
            <input
              type="text"
              id="message"
              placeholder="Envie sua mensagem"
              autocomplete="off"
              class="appearance-none block w-11/12 bg-white text-gray-700 border border-gray-600 rounded-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
              v-model="message"
            />
            <button
              class="bg-green-1000 text-white py-2 px-3 rounded-full border border-gray-400 focus:outline-none"
              @click="sendMessage"
            >
              <i class="ri-send-plane-fill"></i><span class="">Enviar</span>
            </button>
          </div>
        </div>
      </template>
    </modal-layout>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation';
import ModalLayout from '../../../components/Modal';
import { mapGetters } from 'vuex';

export default {
  name: 'InterestsList',

  title() {
    return `${process.env.VUE_APP_NAME} | Plano de Ação - Lista de Interesse`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation,
    ModalLayout
  },

  data() {
    return {
      isLoading: true,
      isLoadingModal: true,
      associate_course_interest_id: '',
      message: '',
      course: {
        name: '',
        programming_type: ''
      },
      participants: [],
      associates: [],
      modalMessage: {
        course: {
          course_name: '',
          started_at: '',
          ended_at: ''
        },
        associate: {
          user_name: '',
          type: '',
          telephone: '',
          qtdCollaborators: '',
          message: [],
          created_at: '',
          cellphone: '',
          associate_name: '',
          address: ''
        }
      },
      columns: [
        'id',
        'name',
        'city',
        'document',
        'email',
        'telephone',
        'cellphone',
        'resume',
        'action'
      ],
      columnsAssociate: [
        'id',
        'name',
        'user',
        'email',
        'telephone',
        'cellphone',
        'action'
      ],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          city: 'Cidade',
          user: 'Usuário',
          document: 'CPF',
          email: 'E-mail',
          telephone: 'Telefone',
          cellphone: 'Celular',
          resume: 'Curriculo',
          action: 'Ações'
        },
        hiddenColumns: ['id', 'city', 'document'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} interessados|{count} Interessados|Um interessado',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum interessado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
      authSyndicate: 'syndicate'
    })
  },

  methods: {
    fetchParticipants() {
      axios
        .get(`/api/planos-acao/lista-interessados/${this.$route.params.id}`)
        .then(({ data }) => {
          this.course.name = data.data.course;
          this.course.programming_type = data.data.programming_type;

          data.data.interests.forEach(participants => {
            if (participants.document) this.participants.push(participants);
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    fetchAssociates() {
      axios
        .get(`/api/areas-de-interesse/associado/${this.$route.params.id}`)
        .then(({ data }) => {
          data.data.forEach(associates => {
            this.associates.push(associates);
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    showModal(row) {
      this.viewMessage(row);
      this.$modal.show('modal');
    },

    async viewMessage(row) {
      this.modalMessage.associate.message = [];
      await axios
        .post(`/api/areas-de-interesse/associate-message/`, {
          course: this.$route.params.id,
          associate: row.associate_id
        })
        .then(({ data }) => {
          this.modalMessage.course.course_name = data.data.course.course_name;
          this.modalMessage.course.started_at = data.data.course.started_at;
          this.modalMessage.course.ended_at = data.data.course.ended_at;

          this.modalMessage.associate.type = data.data.associate.type.label;
          this.modalMessage.associate.telephone = data.data.associate.telephone;
          this.associate_course_interest_id = data.data.associate.associate_course_interest_id;
          this.modalMessage.associate.qtdCollaborators = data.data.associate.number_participants;
          this.modalMessage.associate.cellphone = data.data.associate.cellphone;
          this.modalMessage.associate.associate_name = data.data.associate.associate_name;
          this.modalMessage.associate.address = data.data.associate.address;
        })
        .then(() => {
          this.isLoadingModal = false;
        });

      axios
        .get(
          `/api/areas-de-interesse/mensagens/${this.$route.params.id}/${this.associate_course_interest_id}`
        )
        .then(({ data }) => {
          data.data.forEach(message => {
            this.modalMessage.associate.message.push(message);
          });
        });
    },

    sendMessage() {
      axios
        .post(`/api/areas-de-interesse/enviar-mensagem/${this.authUser.id}`, {
          message: this.message,
          associate_course_interest_id: this.modalMessage.associate.message[0]
            .associate_course_interest_id
        })
        .then(({ data }) => {
          this.$toast.success(data.data.message);
          this.$modal.hide('modal');
          this.message = '';
        });
    },

    goBack() {
      this.$router.go(-1);
    }
  },

  created() {
    this.fetchParticipants();
    this.fetchAssociates();
  }
};
</script>

<style scoped></style>
