<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-show="!isLoading">
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Lista de Interessados
        </h1>
      </div>
      <div class="p-4">
        <div class="flex mb-4 justify-end items-center">
          <span>Tipo de Programação:</span>
          <button
            @click="fetchPlans(true)"
            class="flex p-1 border-2 hover:bg-green-1000 text-white w-max rounded-lg ml-3 mr-3"
            :class="
              active == true
                ? 'border-green-1003 bg-green-1003'
                : 'border-green-1002 bg-green-1002'
            "
          >
            Todos
          </button>

          <button
            class="flex p-1 border-2 hover:bg-green-1000 text-white w-max rounded-lg mr-3"
            :class="
              active == programing
                ? 'border-green-1003 bg-green-1003'
                : 'border-green-1002 bg-green-1002'
            "
            v-for="programing in filterProgramming"
            :key="programing"
            @click="fetchPlans(programing)"
          >
            {{ programing }}
          </button>
        </div>
        <v-client-table
          ref="table"
          :data="plans"
          :columns="columns"
          :options="options"
        >
          <template slot="interests" slot-scope="{ row }">
            <div class="flex justify-center items-center">
              <router-link
                class="flex w-max text-green-1002 ml-1 items-center"
                :to="{ name: 'interests-list', params: { id: row.id } }"
                v-tooltip="{
                  content:
                    'Lista com talentos que demonstraram interessados nesse curso.',
                  placement: 'right'
                }"
              >
                {{ row.participants.total + row.associates.total }}
                <i class="ri-contacts-fill text-2xl"></i>
              </router-link>
            </div>
          </template>
          <div class="flex justify-center" slot="prospect" slot-scope="{ row }">
            <router-link
              class="flex p-1 text-green-1002 border-2 border-green-1002 w-max rounded-lg"
              :to="{ name: 'prospect', params: { id: row.id } }"
              v-tooltip="{
                content:
                  'Buscar por talentos que tem area de interesse similar a esse curso para preencher vagas.',
                placement: 'right'
              }"
            >
              <i class="ri-user-add-fill text-2xl"></i>
            </router-link>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import axios from '@/utils/axios';
import DashboardLayout from '../../../layouts/DashboardLayout';
import { mapGetters } from 'vuex';
import LoaderAnimation from '../../../components/LoaderAnimation';

export default {
  name: 'Index',

  title() {
    return `${process.env.VUE_APP_NAME} | Plano de Ação`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: true,
      active: '',
      filterProgramming: [],
      plans: [],
      columns: [
        'id',
        'programming_type',
        'name',
        'occupational_area',
        'started_at',
        'ended_at',
        'interests',
        'prospect'
      ],
      options: {
        sortable: true,
        customSorting: {
          interests: function(ascending) {
            return function(a, b) {
              var lastA = a.participants.total + a.associates.total;
              var lastB = b.participants.total + b.associates.total;

              if (ascending) return lastA >= lastB ? 1 : -1;

              return lastA <= lastB ? 1 : -1;
            };
          }
        },
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          programming_type: 'Tipo de Programação',
          occupational_area: 'Área Ocupacional',
          started_at: 'Data Ini.',
          ended_at: 'Data Fim',
          interests: 'Interesses',
          prospect: 'Propespectar?'
        },
        hiddenColumns: ['id', 'occupational_area'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} planos de ação|{count} planos de ação|Um plano de ação',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum plano de ação encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },
  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
      authSyndicate: 'syndicate'
    })
  },
  methods: {
    fetchPlans(typeProgramation = true) {
      this.plans = [];
      this.active = typeProgramation;

      axios
        .post('/api/planos-acao/', {
          type: typeProgramation,
          idSyndicate: this.authSyndicate.id ?? null
        })
        .then(({ data }) => {
          data.data.forEach(plans => {
            this.plans.push(plans);
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    filter() {
      axios
        .post('/api/planos-acao/', {
          type: true,
          idSyndicate: this.authSyndicate.id ?? null
        })
        .then(({ data }) => {
          this.filterProgramming = [
            ...new Set(data.data.map(item => item.programming_type))
          ];
        });
    }
  },

  created() {
    this.fetchPlans(true);
    this.filter();
  }
};
</script>

<style scoped></style>
