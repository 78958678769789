var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dashboard-layout',[(_vm.isLoading)?_c('loader-animation'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"w-full p-5"},[_c('div',{staticClass:"p-4 flex"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(" Lista de Interessados ")])]),_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"flex mb-4 justify-end items-center"},[_c('span',[_vm._v("Tipo de Programação:")]),_c('button',{staticClass:"flex p-1 border-2 hover:bg-green-1000 text-white w-max rounded-lg ml-3 mr-3",class:_vm.active == true
              ? 'border-green-1003 bg-green-1003'
              : 'border-green-1002 bg-green-1002',on:{"click":function($event){return _vm.fetchPlans(true)}}},[_vm._v(" Todos ")]),_vm._l((_vm.filterProgramming),function(programing){return _c('button',{key:programing,staticClass:"flex p-1 border-2 hover:bg-green-1000 text-white w-max rounded-lg mr-3",class:_vm.active == programing
              ? 'border-green-1003 bg-green-1003'
              : 'border-green-1002 bg-green-1002',on:{"click":function($event){return _vm.fetchPlans(programing)}}},[_vm._v(" "+_vm._s(programing)+" ")])})],2),_c('v-client-table',{ref:"table",attrs:{"data":_vm.plans,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"interests",fn:function({ row }){return [_c('div',{staticClass:"flex justify-center items-center"},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content:
                  'Lista com talentos que demonstraram interessados nesse curso.',
                placement: 'right'
              }),expression:"{\n                content:\n                  'Lista com talentos que demonstraram interessados nesse curso.',\n                placement: 'right'\n              }"}],staticClass:"flex w-max text-green-1002 ml-1 items-center",attrs:{"to":{ name: 'interests-list', params: { id: row.id } }}},[_vm._v(" "+_vm._s(row.participants.total + row.associates.total)+" "),_c('i',{staticClass:"ri-contacts-fill text-2xl"})])],1)]}},{key:"prospect",fn:function({ row }){return _c('div',{staticClass:"flex justify-center"},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content:
                'Buscar por talentos que tem area de interesse similar a esse curso para preencher vagas.',
              placement: 'right'
            }),expression:"{\n              content:\n                'Buscar por talentos que tem area de interesse similar a esse curso para preencher vagas.',\n              placement: 'right'\n            }"}],staticClass:"flex p-1 text-green-1002 border-2 border-green-1002 w-max rounded-lg",attrs:{"to":{ name: 'prospect', params: { id: row.id } }}},[_c('i',{staticClass:"ri-user-add-fill text-2xl"})])],1)}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }