<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-show="!isLoading">
      <button
        type="button"
        class="bg-green-1000 hover:bg-green-1001 text-white px-4 py-2 rounded-lg focus:outline-none"
        @click="goBack"
      >
        Voltar
      </button>
      <div class="py-4 flex">
        <h1 class="text-3xl">Prospectar {{ course.name }}</h1>
      </div>

      <div class="p-4">
        <v-client-table
          ref="table"
          :data="prospect"
          :columns="columns"
          :options="options"
        >
          <div
            class="flex justify-center items-center"
            slot="resume"
            slot-scope="{ row }"
          >
            <router-link
              class="mr-3 text-2xl border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white p-1 rounded-lg focus:outline-none focus:shadow-outline"
              :to="{ name: 'curriculum', params: { id: row.participant } }"
              v-tooltip="{ content: 'Ver Currículo', placement: 'left' }"
              target="_blank"
            >
              <i class="ri-article-line"></i>
            </router-link>
          </div>
          <div
            class="flex justify-center items-center"
            slot="action"
            slot-scope="{ row }"
          >
            <div class="hidden justify-center">
              <router-link
                class="flex px-3 border-2 border-green-1003 bg-green-1003 hover:bg-green-1001 hover:border-green-1001 text-white w-max rounded-lg mr-3"
                :to="{ name: 'sugestions', params: { id: row.participant } }"
                target="_blank"
              >
                Sugerir<i class="ml-1 ri-add-line"></i>
              </router-link>
            </div>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation';

export default {
  name: 'InterestsList',

  title() {
    return `${process.env.VUE_APP_NAME} | Plano de Ação - Lista de Interesse`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: true,
      course: {
        name: ''
      },
      prospect: [],
      columns: [
        'id',
        'name',
        'city',
        'document',
        'email',
        'telephone',
        'cellphone',
        'keywords',
        'resume',
        'action'
      ],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          city: 'Cidade',
          document: 'CPF',
          email: 'E-mail',
          telephone: 'Telefone',
          cellphone: 'Celular',
          keywords: 'Interesse',
          resume: 'Curriculo',
          action: 'Ações'
        },
        hiddenColumns: ['id', 'city', 'document'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} participantes|{count} participantes|Um participante de ação',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum participante encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },
  methods: {
    fetchParticipants() {
      axios
        .get(`/api/planos-acao/prospectar/${this.$route.params.id}`)
        .then(({ data }) => {
          this.course.name = data.data.course;

          data.data.prospect.forEach(participants => {
            if (participants.document) this.prospect.push(participants);
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    goBack() {
      this.$router.go(-1);
    }
  },

  created() {
    this.fetchParticipants();
  }
};
</script>

<style scoped></style>
